<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9067 4.27328C8.9067 3.92754 9.18403 3.64726 9.52614 3.64726H13.212C13.5541 3.64726 13.8315 3.92754 13.8315 4.27328V6.75401C13.8315 7.09975 13.5541 7.38003 13.212 7.38003H9.52614C9.18403 7.38003 8.9067 7.09975 8.9067 6.75401V4.27328Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3692 2.93955C11.0759 2.93955 10.8382 2.69932 10.8382 2.40297C10.8382 1.48816 11.572 0.746553 12.4772 0.746553H14.324C14.4388 0.746553 14.5318 0.652547 14.5318 0.536585C14.5318 0.240237 14.7695 0 15.0627 0C15.356 0 15.5937 0.240237 15.5937 0.536585C15.5937 1.24524 15.0253 1.81972 14.324 1.81972H12.4772C12.1585 1.81972 11.9001 2.08085 11.9001 2.40297C11.9001 2.69932 11.6624 2.93955 11.3692 2.93955Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5586 22C16.253 22 20.0586 18.1541 20.0586 13.4098C20.0586 8.66562 16.253 4.81967 11.5586 4.81967C6.86417 4.81967 3.05859 8.66562 3.05859 13.4098C3.05859 18.1541 6.86417 22 11.5586 22ZM12.9235 7.44704C12.599 7.33771 12.2482 7.51497 12.14 7.84297C12.0318 8.17097 12.2072 8.52549 12.5318 8.63482L13.2007 8.86018C14.6012 9.33196 15.7002 10.4426 16.167 11.8579L16.39 12.534C16.4982 12.862 16.849 13.0392 17.1735 12.9299C17.4981 12.8206 17.6735 12.466 17.5653 12.1381L17.3423 11.462C16.7522 9.67279 15.3629 8.2688 13.5925 7.6724L12.9235 7.44704Z"
    />
  </svg>
</template>
